import React from 'react';

export default function FourohFourPage() {
  return (
    <>
      <div>
        <p>Oh No! This Page Doesn't Exist!</p>
      </div>
    </>
  );
}
